import React, { useEffect, useState } from 'react';
import '../../utils/css/components/general/challengeEnCours.css'
import eye from '../../assets/icon/eye.png';

export default function ListeChallengeEnCours({ challenges, handleDetail }) {
    const [searchTerm, setSearchTerm] = useState(''); // État pour la chaîne de recherche
    const [filteredClients, setFilteredClients] = useState(challenges); // État pour les clients filtrés
    const [numPage, setNumPage] = useState(1)
    const clientsPerPage = 5

    useEffect(() => {
        // Filtrer les clients en fonction de la chaîne de recherche
        const filtered = challenges.filter((client) => {
            if (searchTerm === '') {
                return true; // Si la chaîne de recherche est vide, affiche tous les clients
            }
            return (
                client.name.toLowerCase().includes(searchTerm) ||
                client.start_date.toLowerCase().includes(searchTerm) ||
                client.end_date.toLowerCase().includes(searchTerm) ||
                client.limit_date.toString().includes(searchTerm)
            );
        });
        setFilteredClients(filtered);
    }, [searchTerm, challenges]);

    const indexOfLastClient = numPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
    const paginate = (pageNumber) => {
        setNumPage(pageNumber);
    };
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        paginate(1)
    };
    const formatDate = (date) => {
        const parts = date.split('-')
        return parts[2] + "/" + parts[1] + "/" + parts[0]
    }
    return (
        <>
            <h2 className='title-tab'>Challenges en cours</h2>
            <input
                className='input-find'
                placeholder='Rechercher'
                value={searchTerm}
                onChange={handleSearch}
            ></input>
            <table>
                <thead>
                    <tr>
                        <th>Challenge</th>
                        <th>Période</th>
                        <th>Date limite de dépot</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                        {Array.isArray(currentClients) && currentClients.length > 0 ? (
                            currentClients.map((item,index) => (
                                <tr className='tr-clients' key={index}>
                                    <td>{item.name}</td>
                                    <td>du {formatDate(item.start_date)} au {formatDate(item.end_date)}</td>
                                    <td>{formatDate(item.limit_date)}</td>
                                    <td className='td-detailChallenge' onClick={() => handleDetail(item.name)}>
                                        <img className="img-eye" src={eye} alt="Voir le détail" />
                                        Voir le détail
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">Aucun challenge en cours trouvé.</td>
                            </tr>
                        )}
                </tbody>
            </table>
            <div className='div-bottomTabButtons'>
                <div className="pagination">
                    <button className='button-pagination' onClick={() => paginate(numPage - 1)} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{numPage + " / " + Math.ceil(filteredClients.length / clientsPerPage)}</p>
                    <button className='button-pagination' onClick={() => paginate(numPage + 1)} disabled={currentClients.length < clientsPerPage}>
                        {">>>"}
                    </button>
                </div>
            </div>
        </>
    );
}
