import eye from '../../assets/icon/eye.png'
import '../../utils/css/components/challenges/boxInfos.css'
export default function BoxInfos({ title, number, handleClick }) {
    return (
        <div className='div-container'>
            <div>
                <p className='p-title'>{title}</p>
                <p className='p-number'>{number}</p>
            </div>
            <div onClick={handleClick} className='div-detail'>
                <img className='img-eye' src={eye}></img>
                <p className='p-title'>Voir le détail</p>
            </div>
        </div>
    )
}