import React, { useState } from 'react';
import '../../utils/css/components/challenges/steps.css';
//import iconActif from '../../assets/icon/iconActif.png'; // Assurez-vous que le chemin d'accès est correct

export default function Step4({ infos, handleChangeStep}) {
    const [pharmacie, setPharmacie] = useState('');
    const [typePharmacie, setTypePharmacie] = useState(0); // 0 = toutes les pharmacies & 1 = pharmacies spécifiques
    const [status, setStatus] = useState(0); // 0 = actif & 1 = inactif

    const handleTypePharmacieChange = (type) => {
        setTypePharmacie(type);
    };

    const toggleStatus = () => {
        setStatus(status === 0 ? 1 : 0);
    };

    return (
        <div className='div-steps-container'>
            <h2 className='h2-title no-margin'>Etape 4</h2>
            <p>Paramétrez votre challenge</p>

            <div className='div-pharmacie-participantes'>
                <h2 className='h2-title'>Pharmacies Participantes</h2>
                <div onClick={()=>handleTypePharmacieChange(0)} className='div-pharmacieParticipantes'>
                    <div className={'div-button ' + (typePharmacie === 0 ? 'actifType' : '')}></div>
                    <p>Toutes les pharmacies</p>
                </div>
                <div onClick={()=>handleTypePharmacieChange(1)} className='div-pharmacieParticipantes'>
                    <div className={'div-button ' + (typePharmacie === 1 ? 'actifType' : '')}></div>
                    <p>Une pharmacie en particulier</p>
                
                {typePharmacie === 1 && (
                    <input
                    type="text"
                    value={pharmacie}
                    className='input-nom-pharmacie'
                    onChange={(e) => setPharmacie(e.target.value)}
                    placeholder="Nom de la pharmacie"
                    />
                    )}
                </div>
            </div>
            <div className='div-statut'>
                <h2 className='h2-title'>Statut</h2>
                <p>Permet de choisir si vous souhaitez lancer le challenge maintenant ou plus tard</p>
                <div className='statut-toggle' onClick={toggleStatus}>
                    <div className={'div-button2 ' + (status === 1 ? 'actifType2' : '')}></div>
                    <p className='p-status'> {status === 0 ? 'Actif' : 'Inactif'}</p>
                </div>
            </div>
            <div className='div-next-previous'>
                <p onClick={() => handleChangeStep({ 'pharmacie': pharmacie, 'typePharmacie': typePharmacie, 'status': status }, 4, -1)} className='p-previous'>Précédent</p>
                <p onClick={() => handleChangeStep({ 'pharmacie': pharmacie, 'typePharmacie': typePharmacie, 'status': status }, 4, 1)} className='p-next'>Valider</p>
            </div>
        </div>
    );
}
