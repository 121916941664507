import React, { useState } from 'react';
import '../../utils/css/components/challenges/steps.css';

export default function Step3({ infos, handleChangeStep }) {
    const [dateDebut, setDateDebut] = useState(infos.start_date || '');
    const [dateFin, setDateFin] = useState(infos.end_date || '');
    const [dateLimiteDepot, setDateLimiteDepot] = useState(infos.limit_date || '');

    const handleDateChange = (setter) => (e) => {
        setter(e.target.value);

    };

    return (
        <div className='div-steps-container'>
            <h2 className='h2-title no-margin'>Etape 3</h2>
            <p>Sélectionnez la durée de votre challenge</p>
            <div className='div-duree'>
                <h2 className='h2-title'>Durée du Challenge</h2>
                <div className='div-inputs-date'>
                <p>Du</p>
                <input 
                    type="date" 
                    className='input-date'
                    value={dateDebut} 
                    onChange={handleDateChange(setDateDebut)} 
                    />
                <p>au</p>
                <input 
                    type="date" 
                    className='input-date'
                    value={dateFin} 
                    onChange={handleDateChange(setDateFin)} 
                    />
                    </div>
            </div>
            <div className='div-date-limite'>
                <h2 className='h2-title'>Date limite de Dépôt</h2>
                <input 
                    type="date" 
                    className='input-date'
                    value={dateLimiteDepot} 
                    onChange={handleDateChange(setDateLimiteDepot)} 
                />
            </div>
            <div className='div-next-previous'>
                <p onClick={() => handleChangeStep({'start_date': dateDebut, 'end_date': dateFin, 'limit_date': dateLimiteDepot}, 3, -1)} className='p-previous'>Précédent</p>
                <p onClick={() => handleChangeStep({'start_date': dateDebut, 'end_date': dateFin, 'limit_date': dateLimiteDepot}, 3, 1)} className='p-next'>Suivant</p>
            </div>
        </div>
    );
}
