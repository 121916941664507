import React, { useEffect, useState } from 'react';
import '../../utils/css/views/clients.css';
import { useGlobalState } from '../../views/GlobalStateContext';
import file from '../../assets/icon/file.png';

export default function ListeDepot({ depots = [], handlePDV, challengeID }) {
    const { urlAPI } = useGlobalState();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredClients, setFilteredClients] = useState(depots);
    const [selectedPharmacies, setSelectedPharmacies] = useState([]);
    const [statusFilter, setStatusFilter] = useState('tous');
    const [totalGain, setTotalGain] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);

    const [numPage, setNumPage] = useState(1);
    const clientsPerPage = 10;

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        paginate(1);
    };

    useEffect(() => {
        if (!Array.isArray(depots)) {
            console.error("depots is not an array or is undefined", depots);
            return;
        }

        const filtered = depots.filter((depot) => {
            const matchesSearchTerm = searchTerm === '' || depot.pharmacy.name.toLowerCase().includes(searchTerm) ||
                depot.customerCode.toString().includes(searchTerm) ||
                depot.sell_date.toString().includes(searchTerm);

            const matchesStatus = statusFilter === 'tous' ||
                (statusFilter === 'validé' && depot.isValidated === true) ||
                (statusFilter === 'refusé' && depot.isDenied === true) ||
                (statusFilter === 'à valider' && depot.isValidated === false && depot.isDenied === false);

            return matchesSearchTerm && matchesStatus;
        });
        setFilteredClients(filtered);
    }, [searchTerm, depots, statusFilter]);

    useEffect(() => {
        if (statusFilter === 'challenge validé' && challengeID) {
            fetchValidatedChallenges(challengeID);
        } else if (statusFilter === 'challenge payé') {
            fetchPaidChallenges(challengeID);
        }
    }, [statusFilter, challengeID, searchTerm]);

    const fetchValidatedChallenges = async (challengeId) => {
        try {
            const url = `${urlAPI}/admin/sells/validated?challengeId=${challengeId}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (!Array.isArray(data)) {
                    console.error("Fetched data is not an array", data);
                    return;
                }
                const filtered = data.filter(pharmacy => {
                    return searchTerm === '' || pharmacy.name.toLowerCase().includes(searchTerm) ||
                        pharmacy.customerCode.toString().includes(searchTerm);
                });

                setFilteredClients(filtered.map(pharmacy => ({
                    pharmacy: {
                        name: pharmacy.name
                    },
                    customerCode: pharmacy.customerCode,
                    totalGain: pharmacy.sells.reduce((acc, curr) => acc + parseFloat(curr.totalGainVente), 0).toFixed(2)
                })));
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const calculateTotalPaid = (data) => {
        const totalPaid = data.reduce((sum, pharmacy) => {
            const pharmacyTotalGain = pharmacy.sells.reduce((pharmacySum, sell) => {
                return pharmacySum + parseFloat(sell.totalGainVente);
            }, 0);
            return sum + pharmacyTotalGain;
        }, 0);

        setTotalPaid(totalPaid.toFixed(2));
    }

    const fetchPaidChallenges = async (challengeID) => {
        try {
            const url = `${urlAPI}/admin/sells/getPaidChallenges?challengeId=${challengeID}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                calculateTotalPaid(data);

                if (!Array.isArray(data)) {
                    console.error("Fetched data is not an array", data);
                    return;
                }

                const mappedData = data.map(pharmacy => ({
                    pharmacy: {
                        name: pharmacy.name
                    },
                    customerCode: pharmacy.customerCode,
                    totalGain: pharmacy.sells.reduce((acc, curr) => acc + parseFloat(curr.totalGainVente), 0).toFixed(2)
                }));

                const filtered = mappedData.filter(pharmacy => {
                    return searchTerm === '' || pharmacy.pharmacy.name.toLowerCase().includes(searchTerm) ||
                        pharmacy.customerCode.toString().includes(searchTerm);
                });

                setFilteredClients(filtered);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allSelectedPharmacies = filteredClients.map(client => ({
                customerCode: client.customerCode,
                totalGain: client.totalGain
            }));
            setSelectedPharmacies(allSelectedPharmacies);
            const newTotalGain = allSelectedPharmacies.reduce((sum, pharmacy) => sum + parseFloat(pharmacy.totalGain || 0), 0);
            setTotalGain(newTotalGain);
        } else {
            setSelectedPharmacies([]);
            setTotalGain(0);
        }
    };

    const handleSelectPharmacy = (customerCode, total_gain) => {
        setSelectedPharmacies(prevState => {
            const isSelected = prevState.some(pharmacy => pharmacy.customerCode === customerCode);
            let updatedPharmacies;
            if (isSelected) {
                updatedPharmacies = prevState.filter(pharmacy => pharmacy.customerCode !== customerCode);
            } else {
                updatedPharmacies = [...prevState, { customerCode, total_gain }];
            }

            const newTotalGain = updatedPharmacies.reduce((sum, pharmacy) => sum + parseFloat(pharmacy.total_gain || 0), 0);
            setTotalGain(newTotalGain);

            return updatedPharmacies;
        });
    };

    const exportGladyProof = async () => {
        try {
            const url = `${urlAPI}/admin/exportGlady`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ listCustomerCode: selectedPharmacies.map(pharmacy => [pharmacy.customerCode, pharmacy.total_gain]) })
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'export_glady.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                throw new Error('Failed to export Glady proofs');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePayment = async () => {
        try {
            const listCustomerCode = selectedPharmacies.map(pharmacy => pharmacy.customerCode);
            const challengeId = challengeID;
            const url = `${urlAPI}/admin/processPayment`;
            
            // Ajout de challengeId dans le body
            const bodyData = {
                listCustomerCode,
                challengeId
            };
    
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyData)
            });
    
            if (response.ok) {
                alert('Paiement effectué avec succès.');
                setFilteredClients(prevClients =>
                    prevClients.map(client =>
                        listCustomerCode.includes(client.customerCode)
                            ? { ...client, isPaid: true }
                            : client
                    )
                );
                setStatusFilter('challenge payé');
            } else {
                const errorText = await response.text();
                console.error('Failed to process payment:', errorText);
                throw new Error('Failed to process payment');
            }
        } catch (error) {
            console.error('Error during payment processing:', error);
            alert('Erreur lors du traitement du paiement.');
        }
    };
    
    const indexOfLastClient = numPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const paginate = (pageNumber) => {
        setNumPage(pageNumber);
    };

    return (
        <>
            <h2 className='title-tab'>Dépôts</h2>
            <div className="radio-container">
                <input type="radio" id="tous" name="statusFilter" value="tous" checked={statusFilter === 'tous'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="tous" className="radio-label">Tous</label>

                <input type="radio" id="valide" name="statusFilter" value="validé" checked={statusFilter === 'validé'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="valide" className="radio-label">Validé</label>

                <input type="radio" id="aValider" name="statusFilter" value="à valider" checked={statusFilter === 'à valider'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="aValider" className="radio-label">À valider</label>

                <input type="radio" id="refuse" name="statusFilter" value="refusé" checked={statusFilter === 'refusé'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="refuse" className="radio-label">Refusé</label>

                <input type="radio" id="challengeValide" name="statusFilter" value="challenge validé" checked={statusFilter === 'challenge validé'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="challengeValide" className="radio-label">Challenge validé</label>

                <input type="radio" id="challengePaye" name="statusFilter" value="challenge payé" checked={statusFilter === 'challenge payé'} onChange={(e) => setStatusFilter(e.target.value)} className="radio-button" />
                <label htmlFor="challengePaye" className="radio-label">Challenge payé</label>
            </div>

            <div className="search-validate-container">
                <input
                    className='input-find'
                    placeholder='Rechercher'
                    value={searchTerm}
                    onChange={handleSearch}
                ></input>

                {statusFilter === 'challenge validé' && (
                    <div className="select-validate-container">
                        <p className='select-total-gain'>total : {totalGain} €</p>
                        <div className='select-checkbox'>
                            <input
                                type="checkbox"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={selectedPharmacies.length === filteredClients.length}
                            />
                            <label htmlFor="selectAll">Tout sélectionner</label>
                        </div>
                        <div className='buttonGlady'>
                            <button onClick={exportGladyProof}>Exporter fichier GLADY</button>
                            <button onClick={handlePayment}>Payer</button>
                        </div>
                    </div>
                )}

                {statusFilter === 'challenge payé' && (
                    <div>
                        <p className='totalPaid'>total payé : {totalPaid}€</p>
                    </div>
                )}
            </div>

            <table>
                <thead>
                    <tr>
                        {statusFilter === 'challenge validé' && <th></th>}
                        <th>Pharmacie</th>
                        <th>Code client</th>
                        {statusFilter !== 'challenge validé' && statusFilter !== 'challenge payé' && <th>Date de dépôt</th>}
                        {statusFilter !== 'challenge validé' && statusFilter !== 'challenge payé' && <th>Status</th>}
                        {(statusFilter === 'challenge validé' || statusFilter === 'challenge payé') && <th>Total Gain</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentClients.map((item, index) => (
                        <tr className='tr-clients' key={index}>
                            {statusFilter === 'challenge validé' && (
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedPharmacies.some(pharmacy => pharmacy.customerCode === item.customerCode)}
                                        onChange={() => handleSelectPharmacy(item.customerCode, item.totalGain)}
                                    />
                                </td>
                            )}
                            <td>{item.pharmacy.name}</td>
                            <td>{item.customerCode}</td>
                            {statusFilter !== 'challenge validé' && statusFilter !== 'challenge payé' && <td>{item.sell_date || "N/A"}</td>}
                            {statusFilter !== 'challenge validé' && statusFilter !== 'challenge payé' && (
                                <td>
                                    {item.isValidated === true
                                        ? "Validé"
                                        : item.isDenied === true
                                            ? "Refusé"
                                            : "À valider"}
                                </td>
                            )}
                            {(statusFilter === 'challenge validé' || statusFilter === 'challenge payé') && <td>{item.totalGain}</td>}
                            {statusFilter !== 'challenge validé' && statusFilter !== 'challenge payé' &&
                                <td className='td-delete' onClick={() => handlePDV(item.id, item.name, item.customerCode)}>
                                    <img className="img-trash" src={file} alt="Voir les ventes" />Voir les ventes
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='div-bottomTabButtons'>
                <div className="pagination">
                    <button className='button-pagination' onClick={() => paginate(numPage - 1)} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{numPage + " / " + Math.ceil(filteredClients.length / clientsPerPage)}</p>
                    <button className='button-pagination' onClick={() => paginate(numPage + 1)} disabled={currentClients.length < clientsPerPage}>
                        {">>>"}
                    </button>
                </div>
            </div>
        </>
    );
}
