import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../utils/css/views/detailsChallenge.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import arrow from '../assets/icon/arrow.png';
import edit from '../assets/icon/edit.png';
import ListeDepot from '../components/general/ListeDepot';
import PopUp_PDV from '../components/general/PopUp_PDV';
import { useGlobalState } from '../views/GlobalStateContext';
import Loader from '../components/general/loaderGIF';

export default function DetailsChallenge() {
    const { urlAPI } = useGlobalState();
    const [isLoading, setIsLoading] = useState(false);
    const [popUpPDV, setPopUpPDV] = useState(false);
    const [infosChallenges, setInfosChallenges] = useState({});
    const [idToRemember, setIdToRememner] = useState('');
    const [challengeID, setChallengeID] = useState(null);
    const [pharmacyID, setPharmacyID] = useState('');
    const [pharmacyNameToRemember, setPharmacyNameToRemember] = useState('');
    const [depots, setDepots] = useState([]);

    const { nom } = useLocation().state;
    const navigate = useNavigate();
    const handleComeBack = () => {
        navigate('/challenges');
    };
    const handleModif = () => {
        navigate('/challenges/modification', { state: { nomChallenge: nom } });
    };

    const handlePDV = (id, pharmacyName, customerCode) => {
        setIdToRememner(id);
        setPharmacyNameToRemember(pharmacyName);
        setPharmacyID(customerCode);
        setPopUpPDV(true);
    };

    const handleClose = () => {
        setPopUpPDV(false);
    };

    useEffect(() => {
        getChallengeDetail();
    }, []);

    const getChallengePDV = async (idChallenge) => {
        try {
            const url = `${urlAPI}/admin/sells?idSells=${idChallenge}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });
            const data = await response.json();
            setDepots(data.sells);
        } catch (error) {
            console.error(error);
        }
    };

    const getChallengeDetail = async () => {
        setIsLoading(true);
        try {
            const url = `${urlAPI}/admin/challenge?name=${nom}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });
            const data = await response.json();
            setInfosChallenges(data.challenge);
            setChallengeID(data.challenge.id);
            getChallengePDV(data.challenge.id);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateStatus = async () => {
        setIsLoading(true);
        await getChallengeDetail();
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data'>
                <Header title={"Challenge du moment"} bold={"moment"} />
                <div onClick={handleComeBack} className='div-comeback'>
                    <img className='img-comeback' src={arrow} />
                    <p className='p-comeback'>Retour</p>
                </div>
                <div className='div-titleEdit'>
                    <h2 className='title-tab'>{nom}</h2>
                    <div className='div-editButton' onClick={handleModif}>
                        <img className='img-editButton' src={edit} />
                        <p className='p-editButton'>Modifier</p>
                    </div>
                </div>
                <div className='div-3div'>
                    <div className='div-infosChallenges'>
                        <p className='p-infosTitle'>Description : </p>
                        <p>{infosChallenges.description}</p>
                    </div>
                    <div className='div-infosChallenges'>
                        <p className='p-infosTitle'> Date limite de dépôt : {formatDate(infosChallenges.limit_date)}</p>
                        {infosChallenges.customerCode && (
                            <>
                                <p className='p-infosTitle'> Pharmacies participantes : </p>
                                <p>{infosChallenges.customerCode}</p>
                            </>
                        )}
                    </div>
                    <div className='div-infosChallenges'>
                        <p className='p-infosTitle'>Produits/Gammes concernées : </p>
                        <p className='p-infosProduit'>{infosChallenges.product_objectif}</p>
                    </div>
                </div>
                <ListeDepot depots={depots} handlePDV={handlePDV} challengeID={challengeID} />
                {popUpPDV && (
                    <PopUp_PDV
                        handleClose={handleClose}
                        sells={idToRemember}
                        pharmacyName={pharmacyNameToRemember}
                        customerCode={pharmacyID}
                        challengeType={infosChallenges.type_challenge}
                        challengeID={challengeID}
                        onUpdateStatus={updateStatus}
                    />
                )}
            </div>
        </div>
    );
}

const formatDate = (date) => {
    if (date) {
        const parts = date.split('-');
        return parts[2] + "/" + parts[1] + "/" + parts[0];
    } else {
        return 'no date';
    }
};
