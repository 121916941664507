import React, { useState, useEffect } from 'react';
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import '../utils/css/views/viewInscription.css';
import { useGlobalState } from '../views/GlobalStateContext';

export default function ViewInscription() {
    const { urlAPI } = useGlobalState();
    const [isLoading, setIsLoading] = useState(false);
    const [allChallenges, setAllChallenges] = useState([]);
    const [participations, setParticipations] = useState([]);
    const [temporarilyOpenChallenges, setTemporarilyOpenChallenges] = useState([]);
    const [customerCode, setCustomerCode] = useState("");
    const [messageErreur, setMessageErreur] = useState("");
    const [updateData, setUpdateData] = useState(false); // État de déclenchement de mise à jour

    // Requête pour récupérer les challenges temporairement ouverts lors du chargement de la page
    useEffect(() => {
        const fetchTemporarilyOpenChallenges = async () => {
            try {
                const response = await fetch(`${urlAPI}/admin/getTemporarilyOpen`);
                const data = await response.json();
                setTemporarilyOpenChallenges(data.listChallengeTemporarilyOpen || []);
            } catch (error) {
                console.error("Erreur lors de la requête temporaire :", error);
            }
        };

        fetchTemporarilyOpenChallenges();
    }, [urlAPI, updateData]); // Dépendance ajoutée pour recharger les données lors d'une mise à jour

    // Fonction pour charger les challenges et participations d'un client donné
    const fetchChallenges = async () => {
        if (!customerCode) {
            setMessageErreur("Veuillez entrer un code client.");
            return;
        }

        setIsLoading(true);
        setMessageErreur(""); // Réinitialisation du message d'erreur
        try {
            const response = await fetch(`${urlAPI}/admin/getChallengeParticipation/${customerCode}`);
            if (response.status === 404) {
                setMessageErreur("Aucune pharmacie existante pour ce code client.");
                setAllChallenges([]); // Réinitialisation de la liste des challenges
                setParticipations([]);
                return;
            }

            const data = await response.json();
            setAllChallenges(data.allChallenges || []);
            setParticipations(data.participe || []);
        } catch (error) {
            console.error("Erreur lors de la requête :", error);
            setMessageErreur("Erreur lors de la connexion.");
        } finally {
            setIsLoading(false);
        }
    };

    // Fonction pour vérifier si un utilisateur est inscrit à un challenge
    const isParticipating = (challengeId) => {
        return participations.find((part) => part.challengeId === challengeId);
    };

    // Fonction pour "S'inscrire"
    const handleSubscribe = async (customerCode, challengeId) => {
        try {
            const response = await fetch(`${urlAPI}/admin/inscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ customerCode, challengeId }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'inscription");
            }

            const data = await response.json();
            console.log("Inscription réussie:", data);

            // Forcer le rechargement des données après inscription
            await fetchChallenges();
        } catch (error) {
            console.error("Erreur lors de l'inscription:", error);
        }
    };

    // Fonction pour "Réouvrir temporairement"
    const handleReopenTemporarily = async (customerCode, challengeId) => {
        try {
            const response = await fetch(`${urlAPI}/admin/openTemporarily`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ customerCode, challengeId }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la réouverture temporaire");
            }

            const data = await response.json();
            console.log("Réouverture temporaire réussie:", data);

            // Forcer le rechargement des données après la réouverture temporaire
            await fetchChallenges();
            setUpdateData(!updateData); // Rechargement de la liste temporaire
        } catch (error) {
            console.error("Erreur lors de la réouverture temporaire:", error);
        }
    };

    // Fonction pour retirer une inscription temporaire
    const handleRemoveTemporary = async (customerCode, challengeId) => {
        try {
            const response = await fetch(`${urlAPI}/admin/closeTemporarilyOpen`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ customerCode, challengeId }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la fermeture temporaire");
            }

            const data = await response.json();
            console.log("Inscription temporaire retirée avec succès:", data);

            // Forcer le rechargement des données après retrait de l'inscription temporaire
            await fetchChallenges();
            setUpdateData(!updateData); // Rechargement de la liste temporaire
        } catch (error) {
            console.error("Erreur lors de la fermeture temporaire:", error);
        }
    };

    return (
        <div>
            <div className='container-homePage'>
                <Sidebar currentPage={"Inscriptions"} />
                <div className='container-all-data'>
                    <Header title={"Gestion des inscriptions"} bold={"inscriptions"} />
                    <div className="container-viewInscription">
                        <h2 className="h2-title ">Listing challenges inscrit</h2>

                        {/* Input pour le code client */}
                        <input
                            className="input-find margin"
                            placeholder="Entrez le code client"
                            value={customerCode}
                            onChange={(e) => setCustomerCode(e.target.value)}
                        />
                        <button className="btn-find" onClick={fetchChallenges} disabled={isLoading}>
                            {isLoading ? 'Chargement...' : 'Valider'}
                        </button>

                        {messageErreur && <p className="error-message">{messageErreur}</p>}

                        {isLoading ? (
                            <p>Chargement des données...</p>
                        ) : (
                            <div className="table-container">
                                <table className="challenge-table">
                                    <thead>
                                        <tr>
                                            <th>Nom du challenge</th>
                                            <th>Description</th>
                                            <th>Statut</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allChallenges.map((challenge) => {
                                            const participation = isParticipating(challenge.id);
                                            return (
                                                <tr key={challenge.id}>
                                                    <td>{challenge.name}</td>
                                                    <td>{challenge.description}</td>
                                                    <td>
                                                        {participation ? "Déjà inscrit" : "Non inscrit"}
                                                    </td>
                                                    <td>
                                                        {participation ? (
                                                            <>
                                                                {!participation.isTemporarilyOpen && (
                                                                    <button onClick={() => handleReopenTemporarily(participation.pharmacyCustomerCode, challenge.id)}>
                                                                        Réouvrir temporairement
                                                                    </button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <button onClick={() => handleSubscribe(customerCode, challenge.id)}>
                                                                S'inscrire
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {/* Tableau d'inscriptions temporaires */}
                        <h2 className="h2-title">Inscription temporaire</h2>
                        <div className="table-container">
                            <table className="challenge-table">
                                <thead>
                                    <tr>
                                        <th>Challenge ID</th>
                                        <th>Code Client</th>
                                        <th>Statut Validation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {temporarilyOpenChallenges.map((challenge) => {
                                        // Trouve le nom du challenge en utilisant allChallenges
                                        const challengeDetails = allChallenges.find((item) => item.id === challenge.challengeId);
                                        const challengeName = challengeDetails ? challengeDetails.name : "Nom non disponible";

                                        return (
                                            <tr key={challenge.id}>
                                                <td>{challengeName}</td> {/* Affiche le nom du challenge au lieu de l'ID */}
                                                <td>{challenge.pharmacyCustomerCode}</td>
                                                <td>{challenge.isValidated ? "Validé" : "Non Validé"}</td>
                                                <td>
                                                    <button onClick={() => handleRemoveTemporary(challenge.pharmacyCustomerCode, challenge.challengeId)}>
                                                        Retirer inscription temporaire
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
