import React from 'react';
import loadingGif from '../../assets/img/loading.gif'; 
import '../../utils/css/components/general/loaderGIF.css';

const Loader = () => {
  return (
    <div className="loader">
      <img src={loadingGif} alt="Chargement" />
    </div>
  );
};

export default Loader;
