import '../../utils/css/components/challenges/steps.css'

export default function AjoutProduitStep2({ produit, onUpdate, onDelete }) {
    const handleNomChange = (e) => {
        onUpdate({ ...produit, product_objectif: e.target.value });
    };

    const handleQuantiteChange = (e) => {
        onUpdate({ ...produit, quantity_objectif: e.target.value });
    };
    return (
        <div>
            <div className='div-double-input'>
                <input type="text" className='input-produit' placeholder='Tapez votre produit/gamme' value={produit.product_objectif} onChange={handleNomChange} />
                <input type="number" className='input-produit' placeholder='Entrez la quantité' value={produit.quantity_objectif} onChange={handleQuantiteChange} />
                <button className='button-delete-produit' onClick={onDelete}>Supprimer</button>
            </div>

        </div>
    )
}