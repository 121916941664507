import React, { useRef } from 'react';

const FileInput = ({ onFileSelect }) => {
    const fileInputRef = useRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                onFileSelect(file.name, base64);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <button class="button-update-doc" onClick={() => fileInputRef.current.click()}>Modifier le fichier</button>
        </div>
    );
};

export default FileInput;
