import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Connexion from './views/Connexion.js';
import Inscription from './views/Inscription.js';
import Clients from './views/Clients.js';
import Accueil from './views/Accueil.js';
import Challenges from './views/Challenges.js'
import DetailsChallenge from './views/DetailsChallenge.js';
import CreateChallenge from './views/CreateChallenge.js';
import ModifChallenge from './views/ModifChallenge.js';
import { GlobalStateProvider } from '../src/views/GlobalStateContext.js'; 
import ViewInscription from './views/ViewInscription.js'

export default function App() {
  return (
    <GlobalStateProvider>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Connexion pageName="connexion" />} />
          {/* <Route path="/inscription" element={<Inscription pageName="inscription" />} /> */}
          <Route path="/clients" element={<Clients pageName="clients" />} />
          <Route path="/accueil" element={<Accueil pageName="accueil" />} />
          <Route path="/challenges" element={<Challenges pageName="challenges" />} />
          <Route path="/challenges/details" element={<DetailsChallenge pageName="detailsChallenge" />} />
          <Route path="/challenges/creation" element={<CreateChallenge pageName="creationChallenge" />} />
          <Route path="/challenges/modification" element={<ModifChallenge pageName="modifChallenge" />} />
          <Route path="/inscription" element={<ViewInscription pageName="inscription" />} />
        </Routes>
      </div>
    </Router>
  </GlobalStateProvider>
  );
}

/*

{\__/}
( ●.●)
/> *Iris Alpaca Meretur Gloriam Aeternam* 

*/
