import React, { createContext, useContext } from 'react';

// Créez un contexte
const GlobalStateContext = createContext();

// Créez un composant fournisseur pour encapsuler votre état global
export const GlobalStateProvider = ({ children }) => { // Correction ici
    const urlAPI = "https://api.challenges-pharmanature.fr";
    // const urlAPI = "https://apitest.challenges-pharmanature.fr";
    return (
        <GlobalStateContext.Provider value={{ urlAPI }}>
            {children} 
        </GlobalStateContext.Provider>
    );
};
// Utilisez un hook pour accéder à la variable globale et à la fonction pour la mettre à jour
export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalState doit être utilisé à l'intérieur de GlobalStateProvider");
    }
    return context;
};