import React, { useState } from 'react';
import '../../utils/css/components/general/popUp.css';

export default function Popup_Validation({ button1, button2, text, motif }) {
    const [textareaValue, setTextareaValue] = useState('');

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleConfirmClick = () => {
        button2(textareaValue);
    };

    return (
        <div className='overlay'>
            <div className={'div-popUp'}>
                <div className='popup-content'>
                    <p>{text}</p>
                    {motif ? (
                        <textarea
                            className='custom-textarea'
                            value={textareaValue}
                            onChange={handleTextareaChange}
                        />
                    ) : null}
                    <p className='p-bold'>Confirmer ?</p>
                </div>
                <div className='div-buttons'>
                    <button className='button-cancel' onClick={button1}>
                        Annuler
                    </button>
                    <button className='button-confirm' onClick={handleConfirmClick}>
                        Confirmer
                    </button>
                </div>
            </div>
        </div>
    );
}