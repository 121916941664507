import '../../utils/css/components/challenges/steps.css';
import { useState, useEffect } from 'react';
import plusIcone from '../../assets/icon/add_circle_black.png';
import AjoutProduitStep2 from './AjoutProduitStep2';

export default function Step2({ infos, handleChangeStep }) {
    const [type, setType] = useState(infos.type_challenge || 0);
    const [produit, setProduit] = useState(infos.product_objectif || '');
    const [quantite, setQuantite] = useState(infos.quantity_objectif || 0);
    const [produitVisible, setAjoutProduitVisible] = useState(false)


    const handleTypeChoice = (typeNum) => {
        setType(typeNum);
    };

    const handleAjoutProduit = () => {
        setAjoutProduitVisible(true)
    };
    const handleSupprimerProduit = () => {
        setAjoutProduitVisible(false)
    };
    const updateProduit = (newProduit) => {
        setProduit(newProduit.product_objectif)
        setQuantite(newProduit.quantity_objectif)
    }



    return (
        <div className='div-steps-container'>
            <h2 className='h2-title no-margin'>Etape 2</h2>
            <p>Sélectionnez votre type de challenge</p>
            <div className='div-type'>
                <h2 className='h2-title'>Type de challenge</h2>
                <div className='divdiv-choix-type'>
                    <div className='div-choix-type' onClick={() => handleTypeChoice(0)} >
                        <div className={'div-button ' + (type === 0 ? 'actifType' : '')}></div>
                        <p>Challenge trimestriel</p>
                    </div>
                    <div className='div-choix-type' onClick={() => handleTypeChoice(1)}>
                        <div className={'div-button ' + (type === 1 ? 'actifType' : '')}></div>
                        <p>Challenge exceptionnel</p>
                    </div>
                </div>
                {type === 1 && (
                    <div>
                        <div className='div-titles2'>
                            <h2 className='h2-title'>Produit </h2>
                            {produitVisible ?
                                <AjoutProduitStep2
                                    produit={{product_objectif: produit,quantity_objectif: quantite}}
                                    onUpdate={(newProduit) => updateProduit(newProduit)}
                                    onDelete={() => handleSupprimerProduit()}
                                />
                                :
                                <div className='div-ajoutProduit' onClick={handleAjoutProduit}>
                                    <img className='img-more' src={plusIcone} />
                                    <p>Ajouter un produit / une gamme</p>
                                </div>
                            }
                        </div>
                    </div>
                )}
                <div className='div-next-previous'>
                    <p onClick={() => handleChangeStep({ 'type_challenge': type, 'product_objectif': produit, 'quantity_objectif': quantite }, 2, -1)} className='p-previous'>Précédent</p>
                    <p onClick={() => handleChangeStep({ 'type_challenge': type, 'product_objectif': produit, 'quantity_objectif': quantite }, 2, 1)} className='p-next'>Suivant</p>
                </div>
            </div>
        </div>
    )
}

