import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../utils/css/views/modifChallenge.css';

import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import plusIcone from '../assets/icon/add_circle_black.png';
import Loader from '../components/general/loaderGIF';
import AjoutProduitStep2 from '../components/challenges/AjoutProduitStep2';
import { useGlobalState } from '../views/GlobalStateContext'

export default function ModifChallenge() {
    const navigate = useNavigate()
    const { urlAPI } = useGlobalState();
    const [isLoading, setIsLoading] = useState(false);
    const { nomChallenge } = useLocation().state;
    const [infosChallenges,setInfosChallenges] = useState([])
    const [infoBase, setInfoBase] = useState(true)

    const handleChangeInfo = (changeInfoBase) => {
        setInfoBase(changeInfoBase)
    }


    useEffect(() => {
        setIsLoading(true); // Commencer le chargement
        getChallengeDetail().finally(() => setIsLoading(false)); // Terminer le chargement
    }, []);
    useEffect(()=>{
        setNom(infosChallenges.name)
        setDescription(infosChallenges.description)
        setType(infosChallenges.type_challenge)
        setTypePharmacie(infosChallenges.type_challenge)
        setDateDebut(infosChallenges.start_date)
        setDateFin(infosChallenges.end_date)
        setDateLimiteDepot(infosChallenges.limit_date)
        setPharmacie(infosChallenges.customerCode)
    }, [infosChallenges])

    const getChallengeDetail = async()=>{
        try{
            const url = `${urlAPI}/admin/challenge?name=${nomChallenge}`
            const response = await fetch(url,{
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }, 
            })
            const data = await response.json()
            setInfosChallenges(data.challenge)
        }
        catch(error){
            console.error(error);
        }
    }

    const saveChallenge = async () => {
        setIsLoading(true); // Commencer le chargement
        try{
            const url = `${urlAPI}/admin/updateChallenge`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body: JSON.stringify({
                    "name":nomChallenge,
                    "new_name":nom,
                    "new_description":description,
                    "new_type_challenge":type,
                    "new_start_date":dateDebut,
                    "new_end_date":dateFin,
                    "new_limit_date":dateLimiteDepot,
                    // "new_product_objectif":0,
                    // "new_quantity_objectif":"",
                    // "new_reward_objectif":1500,
                    "new_customerCode":pharmacie
                })
            })
            const data = await response.json()
            setInfosChallenges(data.challenge)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Terminer le chargement
        }
    };

    const handleSaveChallenge = () => {
        saveChallenge()
        navigate('/challenges')
    }


    // step 1 
    const [nom, setNom] = useState('')

    const [description, setDescription] = useState('')
    const handleNomChange = (e) => {
        setNom(e.target.value)
    }
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    // step 2 
    const [type, setType] = useState(0);
    const [listeProduits, setListeProduits] = useState([]);

    useEffect(() => {
        if (infosChallenges.listeProduits) {
            setListeProduits(infosChallenges.listeProduits);
        }
    }, [infosChallenges.listeProduits]);

    const handleTypeChoice = (typeNum) => {
        setType(typeNum);
    };

    const handleAjoutProduit = () => {
        setListeProduits([...listeProduits, { nom: '', quantite: 0 }]);
    };

    const handleSupprimerProduit = (index) => {
        setListeProduits(listeProduits.filter((_, i) => i !== index));
    };

    const updateProduit = (index, produit) => {
        const newProduits = [...listeProduits];
        newProduits[index] = produit;
        setListeProduits(newProduits);
    };


    // step 3 
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState(infosChallenges.dateFin || '');
    const [dateLimiteDepot, setDateLimiteDepot] = useState(infosChallenges.dateLimiteDepot || '');

    const handleDateChange = (setter) => (e) => {
        setter(e.target.value);
    };

    // step 4 
    const [pharmacie, setPharmacie] = useState('');
    const [typePharmacie, setTypePharmacie] = useState(0); // 0 = toutes les pharmacies & 1 = pharmacies pré commande & 2 = pharmacie personnalisé
    const [status, setStatus] = useState(0); // 0 = actif & 1 = inactif

    const handleTypePharmacieChange = (type) => {
        setTypePharmacie(type);
    };

    const toggleStatus = () => {
        setStatus(status === 0 ? 1 : 0);
    };


    if (isLoading) {
        return <Loader />;
    }
    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data'>
                <Header title={"Modifier un challenge"} bold={"Modifier"} />
                <div className='div-3buttons'>
                    <div className='div-2buttons'>
                        <div className={'div-button ' + (infoBase ? 'actif' : '')} onClick={() => handleChangeInfo(true)}>Information de base</div>
                        <div className={'div-button ' + (!infoBase ? 'actif' : '')} onClick={() => handleChangeInfo(false)}>Paramètre du challenge</div>
                    </div>
                    <div className='div-button actif' onClick={handleSaveChallenge}>Sauvegarder</div>
                </div>
                <div className='contain-everything'>
                {
                    infoBase ?
                        <>
                            <h2 className='h2-title'>Nom du challenge</h2>
                            <input className='input-title' placeholder='ex : T1 - 2024'
                                value={nom} onChange={handleNomChange} />
                            <div className='div-titles'>
                                <h2 className='h2-title'>Description </h2>
                                <p>(optionnel)</p>
                            </div>
                            <textarea className='textarea-description' placeholder='ex : T1 - 2024'
                                value={description} onChange={handleDescriptionChange} />
                            <h2 className='h2-title'>Type de challenge</h2>
                            {/* step 2 */}
                            <div className='divdiv-choix-type'>
                                <div className='div-choix-type' onClick={() => handleTypeChoice('0')} >
                                    <div className={'div-button ' + (type === '0' ? 'actifType' : '')}></div>
                                    <p>Challenge trimestriel</p>
                                </div>
                                <div className='div-choix-type' onClick={() => handleTypeChoice('1')}>
                                    <div className={'div-button ' + (type === '1' ? 'actifType' : '')}></div>
                                    <p>Challenge exceptionnel</p>
                                </div>
                            </div>
                            {type === '1' && (
                                <div>
                                    <div className='div-titles2'>
                                        <h2 className='h2-title'>Liste de produits </h2>
                                        {listeProduits.map((produit, index) => (
                                            <AjoutProduitStep2
                                                key={index}
                                                produit={produit}
                                                onUpdate={(newProduit) => updateProduit(index, newProduit)}
                                                onDelete={() => handleSupprimerProduit(index)}
                                            />
                                        ))}
                                    </div>
                                    <div className='div-ajoutProduit' onClick={handleAjoutProduit}>
                                        <img className='img-more' src={plusIcone} />
                                        <p>Ajouter un produit / une gamme</p>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <>
                            <div className='div-duree'>
                                <h2 className='h2-title'>Durée du Challenge</h2>
                                <div className='div-inputs-date'>
                                    <p>Du</p>
                                    <input
                                        type="date"
                                        className='input-date'
                                        value={dateDebut}
                                        onChange={handleDateChange(setDateDebut)}
                                    />
                                    <p>au</p>
                                    <input
                                        type="date"
                                        className='input-date'
                                        value={dateFin}
                                        onChange={handleDateChange(setDateFin)}
                                    />
                                </div>
                            </div>
                            <div className='div-date-limite'>
                                <h2 className='h2-title'>Date limite de Dépôt</h2>
                                <input
                                    type="date"
                                    className='input-date'
                                    value={dateLimiteDepot}
                                    onChange={handleDateChange(setDateLimiteDepot)}
                                />
                            </div>
                            {/* step 4 */}
                            <div className='div-pharmacie-participantes'>
                                <h2 className='h2-title'>Pharmacies Participantes</h2>
                                <div onClick={() => handleTypePharmacieChange('0')} className='div-pharmacieParticipantes'>
                                    <div className={'div-button ' + (typePharmacie === '0' ? 'actifType' : '')}></div>
                                    <p>Toutes les pharmacies</p>
                                </div>
                                <div onClick={() => handleTypePharmacieChange('1')} className='div-pharmacieParticipantes'>
                                    <div className={'div-button ' + (typePharmacie === '1' ? 'actifType' : '')}></div>
                                    <p>Une pharmacie en particulier</p>
                                    {typePharmacie === '1' && (
                                        <input
                                            type="text"
                                            value={pharmacie}
                                            className='input-nom-pharmacie'
                                            onChange={(e) => setPharmacie(e.target.value)}
                                            placeholder="Nom de la pharmacie"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='div-statut'>
                                <h2 className='h2-title'>Statut</h2>
                                <p>Permet de choisiri si vous souhaitez lancer le challenge maintenant ou plus tard</p>
                                <div className='statut-toggle' onClick={toggleStatus}>
                                    <div className={'div-button2 ' + (status === 1 ? 'actifType2' : '')}></div>
                                    <p className='p-status'> {status === 0 ? 'Actif' : 'Inactif'}</p>
                                </div>
                            </div>
                        </>
                }
                </div>
            </div>
        </div>
    )
}

