import React, { useState, useEffect } from 'react';
import '../utils/css/views/clients.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import ListeChallengeEnCours from '../components/general/ListeChallengeEnCours';
import ListeChallengeTermine from '../components/general/ListeChallengeTermine';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/general/loaderGIF';
import add from '../assets/icon/add.png';
import { useGlobalState } from '../views/GlobalStateContext';
import Popup_Validation from '../components/general/PopUp_Validation';

export default function Challenges() {
    const navigate = useNavigate();
    const { urlAPI } = useGlobalState();
    const [isLoading, setIsLoading] = useState(false);
    const [challengesEnCours, setChallengesEnCours] = useState([]);
    const [challengesTermine, setChallengesTermine] = useState([]);
    const [currentQuarter, setCurrentQuarter] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true); // Commence le chargement
        Promise.all([getChallengeOnGoing(), getChallengeDone()]).finally(() => {
            setIsLoading(false); // Termine le chargement une fois toutes les données chargées
        });
    }, []);

    const getChallengeOnGoing = async () => {
        try {
            const url = `${urlAPI}/admin/challengeOnGoing`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }
            });
            const data = await response.json();
            setChallengesEnCours(data.challenges);
            const currentQuarterChallenge = data.challenges.find(challenge => {
                return challenge.type_challenge === '0';
            });

            if (currentQuarterChallenge) {
                const quarter = getQuarter(currentQuarterChallenge.start_date);
                setCurrentQuarter(quarter);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des challenges en cours:', error);
        }
    };


    const getChallengeDone = async () => {
        try {
            const url = `${urlAPI}/admin/challengeDone`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }
            });
            const data = await response.json();
            setChallengesTermine(data.challenges);
        } catch (error) {
            console.error('Erreur lors de la récupération des challenges terminés:', error);
        }
    };

    const handleDetail = (nomChallenge) => {
        navigate('/challenges/details', { state: { nom: nomChallenge } });
    };

    const handleCreateChallenge = () => {
        navigate('/challenges/creation');
    };

    const openPopup = () => {
        setIsPopupOpen(true);
       
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    
    };

    const addPharmacyChalTrim = async () => {
        try {
            const url = `${urlAPI}/admin/addPharmacyChalTrim`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }
            });
            if (response.ok) {
                alert('Toutes les pharmacies non inscrites ont été ajoutées au challenge trimestriel en cours.');
            } else {
                const data = await response.json();
                alert(`Erreur: ${data.error}`);
            }
        } catch (error) {
            console.error('Erreur lors de l\'inscription des pharmacies au challenge trimestriel:', error);
            alert('Une erreur est survenue lors de l\'inscription des pharmacies au challenge trimestriel.');
        }
        closePopup();
    };

    const getQuarter = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth();
        if (month < 3) return "Trimestre 1";
        if (month < 6) return "Trimestre 2";
        if (month < 9) return "Trimestre 3";
        return "Trimestre 4";
    };


    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data'>
                <Header title={"mes Challenges"} bold={"Challenges"} />
                <div className='divdiv-createChallenge'>
                    <div className='addtoChalTrim' onClick={openPopup}>
                        <p className='p-createChallenge'>Ajouter les pharmacies au challenge : {currentQuarter}</p>
                    </div>
                    <div className='div-createChallenge' onClick={handleCreateChallenge}>
                        <img className='img-createChallenge' src={add} alt="Ajouter"/>
                        <p className='p-createChallenge'>Créer un challenge</p>
                    </div>
                </div>
                <ListeChallengeEnCours challenges={challengesEnCours} handleDetail={handleDetail}/>
                <ListeChallengeTermine challenges={challengesTermine} handleDetail={handleDetail}/>
            </div>
            {isPopupOpen && (
                <Popup_Validation
                    button1={closePopup}
                    button2={addPharmacyChalTrim}
                    text={`Vous vous apprêtez à ajouter les pharmacies au challenge trimestriel en cours : ${currentQuarter}`}
                    motif={false}
                />
            )}
        </div>
    );
}
