import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../utils/css/components/general/sidebar.css';
import logo from '../../assets/img/logo-prescription-nature-HD 1.png';
import iconDeco from '../../assets/icon/logout.png';

function Sidebar({currentPage}) {
    const navigate = useNavigate();
    const menu = [
        { name: 'Accueil', path: '/accueil' },
        { name: 'Mes Clients', path: '/clients' },
        { name: 'Challenges', path: '/challenges' },
        { name: 'Inscriptions', path: '/inscription'}
    ];
    const handleMenuClick = (path) => {
        navigate(path);
    };

    const handleLogOut = ()=>{
        localStorage.clear()
        navigate('/')
    }

    return (
        <div className='container-sidebar'>
            <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
            {menu.map((item, index) => (
                currentPage === item.name
                    ? <p className="label-sidebar current" key={index} onClick={() => handleMenuClick(item.path)}>{item.name}</p>
                    : <p className="label-sidebar" key={index} onClick={() => handleMenuClick(item.path)}>{item.name}</p>
            ))}
            <div className='footer-sidebar' onClick={handleLogOut}>
                <img className='logout-img-sidebar' src={iconDeco} alt="Déconnexion" />
                <p className='logout-sidebar'>Déconnexion</p>
            </div>
        </div>
    );
}

export default Sidebar;
