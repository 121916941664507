import React, { useState, useEffect } from 'react';
import '../utils/css/views/accueil.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import ListeChallengeEnCours from '../components/general/ListeChallengeEnCours';
import BoxInfos from '../components/challenges/BoxInfos';
import Loader from '../components/general/loaderGIF';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../views/GlobalStateContext'
export default function Accueil() {

    const [challenges,setChallenges] = useState([])
    const { urlAPI } = useGlobalState();
    const [isLoading, setIsLoading] = useState(false);
    const [numInvitation,setNumInvitation] = useState(0)
    const [numJustificatif,setNumJustificatif] = useState(0)
    const [challengesEnCours,setChallengesEnCours] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true); // Commence le chargement
       
        getValidateInvitation().finally(() => setIsLoading(false));
        getChallengeOnGoing().finally(() => setIsLoading(false));
        getValidateJustificatif().finally(() => setIsLoading(false));
    }, []);

    const getChallengeOnGoing = async()=>{
        try{
            const url = `${urlAPI}/admin/challengeOnGoing`
                const response = await fetch(url,{
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }
            })
            const data = await response.json()

            setChallengesEnCours(data.challenges)
        }
        catch(error){
            console.error(error);
        }
    }

    const getValidateInvitation = async() => {
        try {
            const url = `${urlAPI}/admin/invitation`
                const response = await fetch(url,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });

            const data = await response.json();
            setNumInvitation(data.users.length)
        } 
        catch (error) {
            console.error('Erreur lors de la connexion', error);
        }
    }

    const getValidateJustificatif = async() => {
        try {
            const url = `${urlAPI}/admin/sells`
                const response = await fetch(url,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });
            const data = await response.json();
            setNumJustificatif(data.sells.length)
        } 
        catch (error) {
            console.error('Erreur lors de la connexion', error);
        }
    }

    const handleClick = () =>{
        navigate('/clients')
    }
    const handleDetail = (nomChallenge) => {
        navigate('/challenges/details', {state : {'nom':nomChallenge}})
    }

    const handleClickChallenge = () => {
        navigate('/challenges')
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Accueil"} />
            <div className='container-all-data'>
                <Header title={"Tableau de bord"} bold={"Tableau"} />
                <h2 className='title-tab'>Dernières activités</h2>
                <div className='div-boxInfos'>
                    <BoxInfos title={"Inscription à valider"} number={numInvitation} handleClick={handleClick}/>
                    <BoxInfos title={"Justificatifs à valider"} number={numJustificatif} handleClick={handleClickChallenge}/>
                </div>
                <ListeChallengeEnCours challenges={challengesEnCours} handleDetail={handleDetail}/>
            </div>
        </div>
    );
}

