import React, { useState, useEffect } from 'react';
import '../../utils/css/components/general/popUpMenu.css'; // Assurez-vous d'ajouter un fichier de style CSS pour personnaliser le style de la popup.

export default function PopUp_Menu({ data, check, checkList }) {
    
    return (
        <div className='div-datas'>
            {data.map((item,index)=>(
                <div className='div-data' key={index} >
                    <input 
                    type='checkbox' 
                    id={index+item} 
                    onChange={()=>check(item)}
                    checked={checkList.includes(item)}
                    ></input>
                    <label htmlFor={index+item}>
                        <p className='p-data'>{item}</p>
                    </label>
                </div>
            ))}
        </div>
    );
}