import React, { useEffect, useState } from 'react';
import '../../utils/css/views/clients.css';
import eye from '../../assets/icon/eye.png';

export default function ListeClients({ clients, handleDelete, handleGraph }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [numPage, setNumPage] = useState(1);
    const clientsPerPage = 10;

    // Utilisez useEffect pour appliquer le filtrage initial et réagir aux modifications des clients
    useEffect(() => {
        applyFilter();
    }, [clients]);
    
    useEffect(() => {
        applyFilter();
    }, [searchTerm]);
    
    const applyFilter = () => {
        const uniquePharmacies = new Map();
        clients.forEach(client => {
            const uniqueKey = client.customerCode;  
            if (!uniquePharmacies.has(uniqueKey)) {
                uniquePharmacies.set(uniqueKey, client);
            }
        });
    
        const filtered = Array.from(uniquePharmacies.values()).filter(client => {
            const pharmacyName = client.name ? client.name.toLowerCase() : '';
            const email = client.email ? client.email.toLowerCase() : '';
            const customerCode = client.customerCode ? client.customerCode.toString() : '';
            
            return searchTerm === '' || pharmacyName.includes(searchTerm)
                || email.includes(searchTerm) || customerCode.includes(searchTerm);
        });
    
        setFilteredClients(filtered);
        setNumPage(1);  // Réinitialiser la pagination à la page 1 après un nouveau filtrage
    };
    
    
    const indexOfLastClient = numPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const paginate = (pageNumber) => {
        setNumPage(pageNumber);
    };

    return (
        <>
            <h2 className='title-tab'>Liste des clients</h2>
            <input
                className='input-find'
                placeholder='Rechercher'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                ></input>
            <table>
                <thead>
                    <tr>
                        <th>Pharmacie</th>
                        <th>Adresse mail</th>
                        <th>Code client</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentClients.map((client) => (
                        <tr className='tr-clients' key={client.customerCode}>
                            <td>{client.name}</td>
                            <td>{client.email}</td>
                            <td>{client.customerCode}</td>
                            <td className='td-delete' onClick={() => handleGraph(client.customerCode)}> 
                                <img className="img-trash" src={eye} alt="Voir statistiques"/>
                                Statistiques
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='div-bottomTabButtons'>
                <div className="pagination">
                    <button className='button-pagination' onClick={() => paginate(numPage - 1)} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{`${numPage} / ${Math.ceil(filteredClients.length / clientsPerPage)}`}</p>
                    <button className='button-pagination' onClick={() => paginate(numPage + 1)} disabled={currentClients.length < clientsPerPage}>
                        {">>>"}
                    </button>
                </div>
            </div>
        </>
    );
}

