import '../../utils/css/components/general/tableauPDV.css'
import { useGlobalState } from '../../views/GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function TableauPDV({ ventes, id }) {
    const navigate = useNavigate();
    const { urlAPI } = useGlobalState([]);
    const [venteData, setVenteData] = useState(ventes);
    const [totalProduct, setTotalProduct] = useState();
    const [productName, setProductName] = useState('');
    const [productQuantity, setProductQuantity] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${urlAPI}/admin/getAllProcutForOnePharmacy/${id}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Problème lors de la récupération des produits');
                }
                let data = await response.json();
                setTotalProduct(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Erreur:', error);
            }
        };

        fetchData();
    }, [id, urlAPI]);

    const handleInputChange = (index, field, value) => {
        const newVenteData = [...venteData];
        newVenteData[index][field] = parseInt(value);
        setVenteData(newVenteData);
    };

    const handleUpdateSell = async () => {
        const url = `${urlAPI}/admin/updateSell/${id}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token,
                },
                body: JSON.stringify({
                    dataSell: venteData,
                }),
            });
            if (!response.ok) {
                throw new Error('Problème lors de la modification de la vente');
            }
            let data = await response.json();
        } catch (error) {
            console.error('Problème lors de la requête de modification de vente', error);
        }
    };

    const handleProductNameChange = (e) => {
        const value = e.target.value;
        setProductName(value);
        if (value && totalProduct && Array.isArray(totalProduct.data)) {
            const filteredSuggestions = totalProduct.data.filter(product =>
                product.name.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setProductName(suggestion.name);
        setSuggestions([]);
    };

    const handleAddProduct = () => {
        const selectedProduct = totalProduct.data.find(product => product.name === productName);
        const newProduct = {
            nom: productName,
            quantite: parseInt(productQuantity, 10),
            gain: selectedProduct?.gain || 0,
            prixUnitaire: selectedProduct?.price || 0
        };
        setVenteData([...venteData, newProduct]);
        setProductName('');
        setProductQuantity('');
    };

    // Recalculer les totaux en fonction des données mises à jour
    const totalQuantite = venteData.reduce((acc, vente) => acc + parseInt(vente.quantite, 10), 0);
    const totalValeur = venteData.reduce((acc, vente) => acc + vente.quantite * vente.gain, 0);

    return (
        <div className='tableauPDV-container'>
            <div className="tableau_pdv_header">
                <p className='corner_topLeft'>Produit</p>
                <p>Quantité</p>
                <p>Gain</p>
                <p>Gain total</p>
                <p className='corner_topRight'>Prix HT</p>
            </div>
            <div className='tableau_pdv_product'>
                {venteData.map((vente, index) => (
                    <div className="tableau_pdv_contain" key={index}>
                        <p className={index === venteData.length - 1 ? 'corner_bottomLeft' : ''}>{vente.nom}</p>
                        <input
                            type="number"
                            value={vente.quantite}
                            onChange={(e) => handleInputChange(index, 'quantite', e.target.value)}
                        />
                        <p>{vente.gain + '€'}</p>
                        <p>{vente.quantite * vente.gain}</p>
                        <p className={index === venteData.length - 1 ? 'corner_bottomRight' : ''}>{vente.prixUnitaire + '€'}</p>
                    </div>
                ))}
            </div>
            <div className='totaux_tableauPDV'>
                <p></p>
                <div className='totaux-p'>
                    <p>{"TOTAL"}</p>
                    <p className='p-vert'>{totalQuantite}</p>
                </div>
                <p></p>
                <div className='totaux-p'>
                    <p>{"TOTAL"}</p>
                    <p className='p-vert'>{totalValeur + "€"}</p>
                </div>
            </div>

            <div className='container-update-sell'>
                <div className='input-group'>
                    <input
                        type="text"
                        value={productName}
                        onChange={handleProductNameChange}
                        placeholder="Nom du produit"
                    />
                    <input
                        type="number"
                        value={productQuantity}
                        onChange={(e) => setProductQuantity(e.target.value)}
                        placeholder="Quantité"
                    />
                    <button className='button-update-sell' onClick={handleAddProduct}>Ajouter</button>
                    <button className='button-update-sell' onClick={handleUpdateSell}>Enregistrer les modifications</button>
                </div>
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion.name}
                        </li>
                    ))}
                </ul>

            </div>

        </div>
    );
}
