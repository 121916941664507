import React, { useState, useEffect } from 'react';
import '../utils/css/views/clients.css';
import Loader from '../components/general/loaderGIF';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import Step1 from '../components/challenges/Step1';
import Step2 from '../components/challenges/Step2';
import Step3 from '../components/challenges/Step3';
import Step4 from '../components/challenges/Step4';
import { useGlobalState } from '../views/GlobalStateContext'

import { useNavigate } from 'react-router-dom';

export default function CreateChallenge() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const { urlAPI } = useGlobalState();
    const [step,setStep] = useState(1)
    const [infos,setInfos] = useState({})
    const [messageErreur,setMessageErreur] = useState()

    

    const handleChangeStep = (info,numStep,where) =>{
        console.log();
        if (numStep < 4 || where < 0) {
            setStep(numStep+where)
        }

        if (numStep === 1) {
            const updatedInfos = { ...infos, name: info.name, description: info.description };
            setInfos(updatedInfos);
        } else if (numStep === 2){
            const updatedInfos = { ...infos, type_challenge: info.type_challenge, product_objectif : info.product_objectif, quantity_objectif : info.quantity_objectif };
            setInfos(updatedInfos);
        } else if (numStep === 3){
            const updatedInfos = { ...infos, start_date: info.start_date, end_date: info.end_date, limit_date: info.limit_date };
            setInfos(updatedInfos);
        } else if (numStep+where === 5){
            // const updatedInfos = { ...infos, pharmacie: info.pharmacie, typePharmacie: info.typePharmacie, status: info.status};
            // setInfos(updatedInfos);
            handleCreateChallenge()
        }
    }

    const handleCreateChallenge = async () => {
        setIsLoading(true); // Commence à afficher le loader
        try {
            const url = `${urlAPI}/admin/createChallenge`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token,
                },
                body: JSON.stringify(infos),
            });

            if (response.status === 201) {
                navigate('/challenge');
            } else {
                setMessageErreur("Erreur lors de la création du challenge.");
                console.log('Erreur lors de la création du challenge. Statut:', response.status);
            }
        } catch (error) {
            console.error('Erreur lors de la requête:', error);
            setMessageErreur("Erreur lors de la connexion.");
        } finally {
            setIsLoading(false); // Cesse d'afficher le loader une fois l'opération terminée
        }
    };

    if (isLoading) {
        return <Loader />; // Affiche le loader si isLoading est true
    }

    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data'>
                <Header title={"créer un challenge"} bold={"challenge"} />
                <div className='div-step'>
                    {step === 1 ? 
                    <Step1 infos={infos} handleChangeStep={handleChangeStep}/>
                    : step === 2 ? 
                    <Step2  infos={infos} handleChangeStep={handleChangeStep}/>
                    : step === 3 ? 
                    <Step3  infos={infos} handleChangeStep={handleChangeStep}/>
                    : step === 4 ? 
                    <Step4  infos={infos} handleChangeStep={handleChangeStep}/>
                    : console.log(infos)
                    }
                </div>
                <h2>{messageErreur}</h2>
            </div>
            
        </div>
    )
}

