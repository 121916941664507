import '../../utils/css/components/challenges/steps.css'
import { useState } from 'react'
export default function Step1({infos,handleChangeStep}){
    const [nom,setNom] =useState(infos.name || '')
    const [description,setDescription] =useState(infos.description || '')
    const handleNomChange = (e)=>{
        setNom(e.target.value)
    }
    const handleDescriptionChange = (e)=>{
        setDescription(e.target.value)
    }

    return (
        <div className='div-steps-container'>
            <h2 className='h2-title no-margin'>Etape 1</h2>
            <p>Nommez et décrivez le challenge</p>
            <div>
                <h2 className='h2-title'>Nom du challenge</h2>
                <input className='input-title' placeholder='ex : T1 - 2024' 
                value={nom} onChange={handleNomChange}/>
                <div className='div-titles'>
                    <h2 className='h2-title'>Description </h2>
                    <p>(optionnel)</p>
                </div>
                <textarea className='textarea-description' placeholder='ex : T1 - 2024' 
                value={description} onChange={handleDescriptionChange}/>
            </div>
            <div className='div-next'>
                <p onClick={()=> handleChangeStep({'name':nom,'description':description},1,1)} className='p-next'>Suivant</p>
            </div>
        </div>
    )
}