import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../views/GlobalStateContext';
import '../../utils/css/components/general/GlobalDoc.css';
import FileInput from './FileInput';

function base64ToBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
}

export function useGlobalDocument(challengeID, challengeType, customerCode) {
    const { urlAPI } = useGlobalState();
    const [globalDocument, setGlobalDocument] = useState(null);
    const [erreur, setErreur] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchGlobalDocument() {
            setIsLoading(true);
            if (challengeType === "0") {
                try {
                    const response = await fetch(`${urlAPI}/admin/getGlobalDocument/${challengeID}/${customerCode}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.token
                        },
                    });
                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.message || 'Échec lors de la récupération du document global.');
                    }
                    const docData = await response.json();
                    if (docData && docData.document) {
                        setGlobalDocument(docData.document);
                    } else {
                        setErreur("Aucun document global n'a été fourni.");
                        setGlobalDocument(null);
                    }
                } catch (error) {
                    console.error("Error fetching global document:", error);
                    setGlobalDocument(null);
                }
                setIsLoading(false);
            }
        }

        fetchGlobalDocument();
    }, [challengeID, challengeType, customerCode, urlAPI]);

    return { globalDocument, erreur, isLoading };
}

export function GlobalDocumentContainer({ globalDocument, erreur, isLoading, sellId, challengeId }) {
    const { urlAPI } = useGlobalState();
    const [fileName, setFileName] = useState("");
    const [fileBase64, setFileBase64] = useState("");
    const [pharmacieId, setPharmacieId] = useState(null); // State to hold the pharmacie ID

    // Fetch pharmacie ID based on sale
    useEffect(() => {
        async function fetchPharmacieId() {
            try {
                const response = await fetch(`${urlAPI}/admin/getPharmacieBySell/${sellId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.token
                    },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Échec lors de la récupération de la pharmacie.');
                }
                const data = await response.json();
                setPharmacieId(data.id_pharmacie);
            } catch (error) {
                console.error("Error fetching pharmacie ID:", error);
            }
        }

        if (sellId && !globalDocument) {
            fetchPharmacieId();
        }
    }, [sellId, urlAPI, globalDocument]);

    const onDownloadGlobalDocument = async () => {
        if (!globalDocument) {
            console.error('Aucun document à télécharger');
            return;
        }
        
        try {
            const blob = await handleDownloadGlobalDocument(globalDocument, urlAPI);
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = globalDocument.name;
            downloadLink.click();
        } catch (error) {
            console.error('Erreur lors du téléchargement :', error);
        }
    };

    const handleFileSelect = (name, base64) => {
        setFileName(name);
        setFileBase64(base64);
    };

    const handleSendFileToS3 = async () => {
        try {
            const response = await fetch(urlAPI + '/s3/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'fileName': fileName,
                    'base64': fileBase64,
                    "folderName": "challenges"
                }),
            });
            if (response.status === 400) {
                alert('Le fichier dépasse la taille maximale autorisée de 10 Mo.');
            }

            const data = await response.json();
            return data.eTag;
        } catch (error) {
            throw new Error('Problème lors de l\'envoi du fichier vers S3');
        }
    };

    const handleCreateDocument = async (eTag) => {
        try {
            const response = await fetch(urlAPI + '/admin/createDocumentAdmin', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token,
                },
                body: JSON.stringify({
                    "name": fileName,
                    "id_challenges": challengeId,
                    "eTag": eTag,
                    "id_document_current": globalDocument ? globalDocument.id : null,
                    "id_pharmacie": globalDocument ? globalDocument.id_pharmacie : pharmacieId 
                }),
            });

            if (!response.ok) {
                throw new Error('Problème lors de la création du document');
            }

            const data = await response.json();
            return data.id_document;
        } catch (error) {
            throw new Error('Problème lors de la création du document');
        }
    };

    const handleReplaceOrAddDocument = async () => {
        try {
            const eTag = await handleSendFileToS3();
            await handleCreateDocument(eTag);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='globalDocument-container'>
            <h3>Document Global</h3>
            {isLoading ? (
                <p>Chargement du document...</p>
            ) : globalDocument ? (
                <div>
                    <p onClick={onDownloadGlobalDocument} style={{ cursor: 'pointer' }}>
                        {globalDocument && globalDocument.name.length > 15 ? 
                        `${globalDocument.name.slice(0, 10)}...${globalDocument.name.substring(globalDocument.name.lastIndexOf('.'))}` : 
                        globalDocument.name
                    }
                    </p>
                    <FileInput onFileSelect={handleFileSelect} />
                    {fileName && (
                        <div>
                            <p className='selectedFile'>
                                Fichier sélectionné : {fileName.length > 15 ? `${fileName.slice(0, 10)}...${fileName.substring(fileName.lastIndexOf('.'))}` : fileName}
                            </p>
                            <button className="button-update-doc" onClick={handleReplaceOrAddDocument}>Confirmer le remplacement</button>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <p>{erreur || "Aucun document global n'a été fourni."}</p>
                    <FileInput onFileSelect={handleFileSelect} />
                    {fileName && (
                        <div>
                            <p className='selectedFile'>
                                Fichier sélectionné : {fileName.length > 15 ? `${fileName.slice(0, 10)}...${fileName.substring(fileName.lastIndexOf('.'))}` : fileName}
                            </p>
                            <button className="button-update-doc" onClick={handleReplaceOrAddDocument}>Ajouter le document</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

async function handleDownloadGlobalDocument(globalDocument, urlAPI) {
    const url = `${urlAPI}/s3/download`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "folderName": "challenges",
            "eTag": globalDocument.eTag
        }),
    });

    if (!response.ok) {
        throw new Error('Problème lors du téléchargement du document');
    }

    const data = await response.json();
    const blob = base64ToBlob(data.base64Data.base64Data);
    return blob;
}
