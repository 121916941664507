import React, { useState, useEffect } from 'react';
import '../utils/css/views/clients.css';
import Sidebar from '../components/general/Sidebar';
import Loader from '../components/general/loaderGIF';
import Header from '../components/general/Header';
import PopUp_Validation from '../components/general/PopUp_Validation';
import PopUp_Graph from '../components/general/PopUp_Graph';
import ListeClients from '../components/general/ListeClients';
import ListeInscription from '../components/general/ListeInscription';
import { useGlobalState } from '../views/GlobalStateContext'
import { Navigate } from 'react-router-dom';

export default function Clients() {
    const { urlAPI } = useGlobalState();
    const [clients, setClients] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [inscriptions, setInscriptions] = useState([])
    const [popUpDelete, setPopUpDelete] = useState(false)
    const [popUpGraph, setPopUpGraph] = useState(false)
    const [popUpCancel, setPopUpCancel] = useState(false)
    const [popUpCheck, setPopUpCheck] = useState(false)

    const [codeToRemember, setCodeToRemember] = useState("")
    const [mailToRemember, setMailToRemember] = useState("")
    const [reload, setReload] = useState(false)


    useEffect(() => {
        setIsLoading(true); // Commence le chargement
        Promise.all([getUsers(), getValidateInvitation()]).finally(() => setIsLoading(false));
    }, [reload]);

    const getValidateInvitation = async() => {
        try {
            const url = `${urlAPI}/admin/invitation`
                const response = await fetch(url,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });

            const data = await response.json();
            setInscriptions(data.users)
        } 
        catch (error) {
            console.error(error);
        }
    }

    const getUsers = async () => {
        try {
            const url = `${urlAPI}/admin/pharmacie`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                }
            })
            const data = await response.json()
    
            setClients(data.pharmacies);
        } catch (error) {
            console.error(error);
        }
    }
    

    const handleDelete = (code) => {
        setCodeToRemember(code)
        setPopUpDelete(true)
    }
    const handleGraph = () => {
        setPopUpGraph(true)
    }
    const deletePharmacie = () => {
        const updatedClients = clients.filter((item) => item.code !== codeToRemember);
        setClients(updatedClients);
        setPopUpDelete(false)
    }
    const closePopUp = () => {
        setPopUpCheck(false)
        setPopUpCancel(false)
        setPopUpGraph(false)
        setPopUpDelete(false)
    }
    // Refus de l'inscription 
    const rejectPharmacie = async (motive) => {
        try {
            const url = `${urlAPI}/admin/invitation/refus`
                const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body:JSON.stringify({
                    motive : motive,
                    email : mailToRemember
                })
            });
        } 
        catch (error) {
            console.error('Erreur lors de la connexion', error);
        }
        setReload(!reload)
        setPopUpCancel(false)
    }
    const handleCancel = (code,email) => {
        setCodeToRemember(code)
        setMailToRemember(email)
        setPopUpCancel(true)
    }

    // Validation de l'inscription
    const acceptPharmacie = async() => {
        try {
            const url = `${urlAPI}/admin/invitation/valide`
                const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body:JSON.stringify({
                    email : mailToRemember
                })
            });
        } 
        catch (error) {
            console.error('Erreur lors de la connexion', error);
        }
        // supprime de la liste des inscriptions
        setReload(!reload)
        setPopUpCheck(false)
    }
    const handleCheck = (code,email) => {
        setCodeToRemember(code)
        setMailToRemember(email)
        setPopUpCheck(true)
    }
    if (isLoading) {
        return <Loader />; // Affiche le loader si isLoading est true
    }
    return (
        <div className='container-homePage'>
            <Sidebar currentPage={"Mes Clients"} />
            <div className='container-all-data'>
                <Header title={"mes clients"} bold={"clients"} />
                <ListeInscription inscriptions={inscriptions} handleCancel={handleCancel} handleCheck={handleCheck}/>
                <ListeClients clients={clients} handleDelete={handleDelete} handleGraph={handleGraph} />
            </div>
            {popUpDelete ?
                <PopUp_Validation button1={closePopUp} button2={deletePharmacie} text={"Vous êtes sur le point de supprimer cette pharmacie."} />
                : null
                }
            {popUpGraph ?
                <PopUp_Graph close={closePopUp}/>
                : null
                }
            {popUpCancel ?
                <PopUp_Validation button1={closePopUp} button2={rejectPharmacie} text={"Veillez entrer le motif du refus"} motif={true} />
                : null
                }
            {popUpCheck ?
                <PopUp_Validation button1={closePopUp} button2={acceptPharmacie} text={"Vous êtes sur le point de valider l'inscription de cette pharmacie."} />
                : null
                }
        </div>
    )
}