import React, { useState,useEffect } from 'react';
import '../../utils/css/components/general/popUpGraph.css'; // Assurez-vous d'ajouter un fichier de style CSS pour personnaliser le style de la popup.
import { Line } from 'react-chartjs-2';
import PopUp_Menu from './PopUp_Menu';

export default function PopUp_Graph({ close }) {

    const [whosActive, setWhosActive] = useState(0)

    // Données qui viendront du back 
    const data = {
        labels: ['Janvier', 'Février', 'Mars', 'Avril'],
        sales: [20, 45, 28, 80]
    }
    const data2 = {
        labels: ['Mai', 'Juin', 'Juillet', 'Aout'],
        sales: [10, 10, 8, 65]
    }
    const data3 = {
        labels: ['Septembre', 'Octobre', 'Novembre', 'Décembre'],
        sales: [40, 45, 20, 70]
    }
    const data4 = {
        labels: ['Janvier', 'Mai', 'Aout', 'Septembre'],
        sales: [15, 90, 28, 4]
    }
    const annee = ["2021","2022","2023","2024"]
    const trimestre = ["T1","T2","T3","T4"]
    const mois = ["Janvier","Février","Mars","Avril","Mai"]
    const [dataCheck, setDataCheck] = useState([])
    ///////////////////////////////////////////
    const handleDataCheck = (newData)=>{
        let newDataList = dataCheck
        if (dataCheck.includes(newData)) {
            newDataList = newDataList.filter((element)=> element !== newData)
        } else {
            newDataList.push(newData)
        }
        setDataCheck(newDataList)
    }



    const [datas, setDatas] = useState(data)
    const chartData = {
        labels: datas.labels,
        datasets: [
            {
                label: 'Ventes',
                data: datas.sales,
                fill: false,
                backgroundColor: '#7CB4A8',
                borderColor: '#7CB4A8',
            },
        ],
    };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                },
                title: {
                    display: false,
                },
            },
            y: {
                grid: {
                    drawBorder: false, // Cache la bordure de l'axe Y
                    drawTicks: false, // Cache les ticks de l'axe Y
                },
                beginAtZero: false,
                title: {
                    display: false, // Cache le titre de l'axe Y
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Cache la légende
            },
            title: {
                display: false, // Cache le titre du graphique
            },
            tooltip: {
                enabled: true, // Active ou désactive les tooltips
                // Vous pouvez personnaliser davantage les tooltips ici
            },
        },
        elements: {
            line: {
                tension: 0.3, // Ajuste la courbure de la ligne (0 pour une ligne droite)
            },
            point: {
                radius: 0, // Cache les points sur la ligne
            },
        },
    };

    const handleChangeGraph = (data, who) => {
        setDatas(data)
        setWhosActive(who)
        if (toggleMenu === who) {
            setToggleMenu(0)
        } else {
            setToggleMenu(who)
        }
    }
    const [toggleMenu,setToggleMenu] = useState(0)

    return (
        <div className='overlay'>
            <div className={"div-popUpGraph"}>
                <div className='div-close'>
                    <button className='button-close' onClick={close}>X</button>
                </div>
                <h2 className='title-namePharmacie'>Pharmacie Trinité</h2>
                <div className='div-choiceDiv'>
                    <div>
                        <button className={'button-range ' + (whosActive === 0 ? 'active' : '')} onClick={() => handleChangeGraph(data, 0)}>Global</button>
                    </div>
                    <div>
                        <button className={'button-range ' + (whosActive === 1 ? 'active' : '')} onClick={() => handleChangeGraph(data2, 1)}>Année</button>
                        {toggleMenu === 1 ? <PopUp_Menu data={annee} check={handleDataCheck} checkList={dataCheck}/> : null}
                    </div>
                    <div>
                        <button className={'button-range ' + (whosActive === 2 ? 'active' : '')} onClick={() => handleChangeGraph(data3, 2)}>Trismestre</button>
                        {toggleMenu === 2 ? <PopUp_Menu data={trimestre} check={handleDataCheck} checkList={dataCheck}/> : null}
                    </div>
                    <div>
                        <button className={'button-range ' + (whosActive === 3 ? 'active' : '')} onClick={() => handleChangeGraph(data4, 3)}>Mois</button>
                        {toggleMenu === 3 ? <PopUp_Menu data={mois} check={handleDataCheck} checkList={dataCheck}/> : null}
                    </div>
                </div>
                <div className="popup-content">
                    <Line data={chartData} options={options} />
                </div>
            </div>
        </div>
    );
}