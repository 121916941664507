import React, { useEffect, useState } from 'react';
import '../../utils/css/components/general/inscriptionAValider.css';
import checkCircle from '../../assets/icon/checkCircle.png';
import cancelCircle from '../../assets/icon/cancelCircle.png';

export default function ListeInscription({ inscriptions, handleCancel, handleCheck }) {
    const [searchTerm, setSearchTerm] = useState(''); // État pour la chaîne de recherche
    const [filteredClients, setFilteredClients] = useState(inscriptions); // État pour les clients filtrés
    const [numPage, setNumPage] = useState(1)
    const clientsPerPage = 3
    // Fonction pour mettre à jour la chaîne de recherche
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        paginate(1)
    };
    useEffect(() => {
        // Filtrer les clients en fonction de la chaîne de recherche
        const filtered = inscriptions.filter((client) => {
            if (searchTerm === '') {
                return true; // Si la chaîne de recherche est vide, affiche tous les clients
            }
            return (
                client.pharmacyName.toLowerCase().includes(searchTerm) ||
                client.email.toLowerCase().includes(searchTerm) ||
                client.customerCode.toString().includes(searchTerm)
            );
        });
        setFilteredClients(filtered);
    }, [searchTerm, inscriptions]);


    const indexOfLastClient = numPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
    const paginate = (pageNumber) => {
        setNumPage(pageNumber);
    };
    return (
        <>
            <h2 className='title-tab'>Inscription à valider</h2>
            <input
                className='input-find'
                placeholder='Rechercher'
                value={searchTerm}
                onChange={handleSearch}
            ></input>
            <table>
                <thead>
                    <tr>
                        <th>Pharmacie</th>
                        <th>Adresse mail</th>
                        <th>Code client</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentClients.map((item) => (
                        <tr className='tr-clients' key={item.customerCode}>
                            <td>{item.pharmacyName}</td>
                            <td>{item.email}</td>
                            <td>{item.customerCode}</td>
                            <td>
                                <button className='button-checkCancel' onClick={()=>handleCheck(item.customerCode, item.email)}>
                                <img className='img-check' src={checkCircle}/>
                                </button>
                                <button className='button-checkCancel' onClick={()=>handleCancel(item.customerCode, item.email)}>
                                <img className='img-cancel' src={cancelCircle}/>
                                </button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='div-bottomTabButtons'>
                <div className="pagination">
                    <button className='button-pagination' onClick={() => paginate(numPage - 1)} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{`${numPage} / ${Math.ceil(filteredClients.length / clientsPerPage)}`}</p>
                        <button className='button-pagination' onClick={() => paginate(numPage + 1)} disabled={numPage >= Math.ceil(filteredClients.length / clientsPerPage)}>
                        {">>>"}
                    </button>
                </div>
                
            </div>
        </>
    );
}
