import React, { useState } from 'react';
import '../../utils/css/components/general/listePDV.css';
import PopUp_Validation from '../../components/general/PopUp_Validation';
import AcceptOrReject from '../../components/documents/ValidateOrRefuse';
import { useGlobalState } from '../../views/GlobalStateContext'

export default function ListePDV({ documents, sells, handledownload }) {
    const { urlAPI } = useGlobalState();
    const [popUpValide, setPopUpvalide] = useState(false);
    const [popUpCancel, setPopUpCancel] = useState(false);
    const [message, setMessage] = useState(''); // État pour stocker le message de succès ou d'erreur
    
    function base64ToBlob(base64Data) {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
      
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
      
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray]);
      }
      
    
      const handleDownload = async (item) => {
        // Logique pour télécharger le document
        // console.log(item); 
   
        try {
            const url = `${urlAPI}/s3/download`
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "folderName": "challenges", 
                    "eTag": item.eTag 
                }),
               
            });
            if (!response.ok) {
                throw new Error('Problème lors du téléchargement du document');
            }
        
            const data = await response.json();
            const blob = base64ToBlob(data.base64Data.base64Data);
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = data.base64Data.fileName; 
            downloadLink.click(); // Nettoyage en supprimant l'élément après le téléchargement
        } catch (error) {
            console.error('Erreur lors du téléchargement :', error);
        }
    };

    const validePDV = async () => {
        try {    const url = `${urlAPI}/admin/sells/valide`
        const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body: JSON.stringify({
                    id: sells
                })
            });
            const data = await response.json(); // Assume que l'API renvoie un JSON
            if (response.ok) {
                setMessage("Vente validée avec succès.");
                setPopUpvalide(false); // Fermer le popup après validation
            } else {
                throw new Error(data.message || "Erreur lors de la validation de la vente.");
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const cancelPDV = async (motive) => {
        try {
            const url = `${urlAPI}/admin/sells/refus`;
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body: JSON.stringify({
                    id: sells, 
                    motive: motive
                })
            });
            const data = await response.json(); // Assume que l'API renvoie un JSON
            if (response.ok) {
                setMessage("Refus de la vente effectué avec succès.");
                setPopUpCancel(false); // Fermer le popup après le refus
            } else {
                throw new Error(data.message || "Erreur lors du refus de la vente.");
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const handlePopUpCancel = () => setPopUpCancel(!popUpCancel);
    const handlePopUpValide = () => setPopUpvalide(!popUpValide);

    return (
        <div className='listPDV-container'>
            <h2 className='listPDV-titre'>Preuves de ventes</h2>
            <div className='listPDV-listPDF'>
        {documents.length > 0 ? (
                documents.map((doc, index) => (
                    <div 
                        key={index} 
                        className='listPDV-linkpdf'
                        onClick={() => handleDownload(doc)} 
                    >
                        <p>{
                            doc.name.length > 15 ? 
                            `${doc.name.slice(0, 10)}...${doc.name.substring(doc.name.lastIndexOf('.'))}` : 
                            doc.name
                        }</p>
                    </div>
                ))
            ) : (
                <p>Aucun document n'a été fourni pour cette vente.</p>
            )}

 
</div>
            <AcceptOrReject
                onValidate={handlePopUpValide}
                onCancel={handlePopUpCancel}
                validateText="Valider"
                cancelText="Refuser"
            />
            {popUpValide && <PopUp_Validation button1={handlePopUpValide} button2={validePDV} text="Vous êtes sur le point de valider les preuves de ventes." />}
            {popUpCancel && <PopUp_Validation button1={handlePopUpCancel} button2={cancelPDV} text="Vous êtes sur le point de refuser les preuves de ventes." motif={true} />}
        </div>
    );
}
