import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../utils/css/views/connexion.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png'
import { useGlobalState } from '../views/GlobalStateContext'

export default function Connexion() {
    let navigate = useNavigate();
    const { urlAPI } = useGlobalState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const url = `${urlAPI}/admin/log`
                const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email_admin: email,
                    mdp_admin: password,
                }),
                
                 
            });

            const data = await response.json();
            // console.log(data);
            if (response.ok) {
                localStorage.setItem('token', data.token);
                // console.log(data); 
                navigate('/accueil'); 
            } else {
                setErrorMessage(data.error || 'Une erreur est survenue lors de la connexion.');
            }
        } catch (error) {
            console.error('Erreur lors de la connexion', error);
            setErrorMessage('Une erreur est survenue lors de la connexion.');
        }
    };

    return (
        <div className='container-connexion-field'>
            <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
            <div className='container-label-text-field'>
                <form onSubmit={handleSubmit}>
                    <div className='container-label-input'>
                        <label className="label-input" htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="input-first"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='container-label-input'>
                        <label className="label-input" htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            className="input"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <p className='forget-password'>Mot de passe oublié ?</p>
                    </div>
                    <div className='container-btn'>
                        <p>{errorMessage}</p>
                        <button className="submit-btn" type="submit">Se connecter</button>
                    </div>
                </form>
            </div>
        </div>
    )
}