import React, { useState, useEffect } from 'react';
import '../../utils/css/components/general/popUpPDV.css';
import TableauPDV from './TableauPDV';
import ListePDV from './ListePDV';
import AcceptOrReject from '../documents/ValidateOrRefuse';
import PopUp_Validation from '../../components/general/PopUp_Validation';
import { useGlobalDocument, GlobalDocumentContainer } from './GlobalDoc';
import Loader from './loaderGIF';
import { useGlobalState } from '../../views/GlobalStateContext';

export default function PopUp_PDV({ handleClose, sells, pharmacyName, challengeType, challengeID, customerCode, onUpdateStatus }) {
    const { urlAPI } = useGlobalState();
    const [popUpValide, setPopUpValide] = useState(false);
    const [popUpCancel, setPopUpCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [ventes, setVentes] = useState([]);
    const { globalDocument, erreur, isLoading: isLoadingGlobalDoc } = useGlobalDocument(challengeID, challengeType, customerCode);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const responseVentes = await fetch(`${urlAPI}/admin/getSellItemsBySell/${sells}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });

            if (!responseVentes.ok) {
                throw new Error("Impossible de récupérer les données des ventes !");
            }
            const dataVentes = await responseVentes.json();
            setVentes(dataVentes.map(item => ({
                nom: item.product_name,
                quantite: item.product_quantity,
                prixUnitaire: item.price,
                gain: item.gain
            })));

            const responseDocs = await fetch(`${urlAPI}/admin/getDocumentBySell/${sells}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
            });
            if (responseDocs.ok) {
                const dataDocs = await responseDocs.json();
                setDocuments(Array.isArray(dataDocs) ? dataDocs : [dataDocs]);
            } else {
                setDocuments([]); 
            }

        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        }
        setIsLoading(false);
    };

    const validePDV = async () => {
        setIsLoading(true);
        try {
            const url = `${urlAPI}/admin/sells/valide`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body: JSON.stringify({ id: sells })
            });
            const data = await response.json();
            if (response.ok) {
                setPopUpValide(false);
                setIsLoading(false);
                handleClose(); 
                onUpdateStatus(); // Appeler la fonction de rappel pour mettre à jour le statut
            } else {
                throw new Error(data.message || "Erreur lors de la validation de la vente.");
            }
        } catch (error) {
            console.error("Erreur lors de la validation de la vente:", error);
            setIsLoading(false);
        }
    };

    const cancelPDV = async (motive) => {
        setIsLoading(true);
        try {
            const url = `${urlAPI}/admin/sells/refus`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.token
                },
                body: JSON.stringify({ id: sells, motive: motive })
            });
            const data = await response.json();
            if (response.ok) {
                setPopUpCancel(false);
                setIsLoading(false);
                handleClose(); 
                onUpdateStatus(); // Appeler la fonction de rappel pour mettre à jour le statut
            } else {
                throw new Error(data.message || "Erreur lors du refus de la vente.");
            }
        } catch (error) {
            console.error("Erreur lors du refus de la vente:", error);
            setIsLoading(false);
        }
    };

    const handlePopUpValide = () => setPopUpValide(!popUpValide);
    const handlePopUpCancel = () => setPopUpCancel(!popUpCancel);

    useEffect(() => {
        fetchData();
    }, [sells, challengeType, customerCode, urlAPI]);

    useEffect(() => {
    }, [sells, challengeID]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='overlay'>
            <div className="div-popUpPDV">
                <div className='container'>
                    <div className='premiere-ligne'>
                        <h2 className='nomPharmacie'>{pharmacyName}</h2>
                        <p className='popUpPDV_xToClose' onClick={handleClose}>X</p>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            {erreur && <p style={{ color: 'red' }}>{erreur}</p>}
                            <TableauPDV ventes={ventes} id={sells}/>
                        </div>
                        <div className='document-container'>
                            {challengeType === "0" ? (
                                <div className='challengeTrim-container'>
                                    <GlobalDocumentContainer globalDocument={globalDocument} erreur={erreur} isLoading={isLoadingGlobalDoc} sellId={sells} challengeId={challengeID} />
                                    <AcceptOrReject
                                        onValidate={handlePopUpValide}
                                        onCancel={handlePopUpCancel}
                                        validateText="Valider"
                                        cancelText="Refuser"
                                    />
                                </div>
                            ) : (
                                <ListePDV documents={documents} sells={sells} />
                            )}
                        </div>
                    </div>
                    {popUpValide && <PopUp_Validation button1={handlePopUpValide} button2={validePDV} text="Vous êtes sur le point de valider les preuves de ventes." />}
                    {popUpCancel && <PopUp_Validation button1={handlePopUpCancel} button2={cancelPDV} text="Vous êtes sur le point de refuser les preuves de ventes." motif={true} />}
                </div>
            </div>
        </div>
    );
}
