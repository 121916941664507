import React from 'react';
import checkCircle from '../../assets/icon/checkCircle.png';
import cancelCircle from '../../assets/icon/cancelCircle.png';

export default function AcceptOrReject({ onValidate, onCancel, validateText, cancelText }) {
    return (
        <div className='listPDV-acceptornot'>
            <div className='listPDV-divCheck' onClick={onValidate}> 
                <img className='listPDV-img-check' src={checkCircle} alt="Valider" />
                <p className='listPDV-check'>{validateText}</p>
            </div>
            <div className='listPDV-divCheck' onClick={onCancel}> 
                <img className='listPDV-img-check' src={cancelCircle} alt="Refuser" />
                <p className='listPDV-check'>{cancelText}</p>
            </div>
        </div>
    );
}


